import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Game } from '../domain/game';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from '../services/message.service';
import { Task } from '../domain/task';
import { Player } from '../domain/player';

@Injectable({
  providedIn: 'root'
})
export class ImpostorService {

  private dummyGame: Game = { gameId: 0, gameName: '', state: '', players: [], requiredPlayerCount: 0, keyPhrase: ''};
  private dummyPlayer: Player = { playerName: '',  playerId: 0,   playerType: 'crew',  status: 0,  remainingTasksCount: 0,  taskList: [] };

  private rootUrl = 'http://impostor.us-east-1.elasticbeanstalk.com/api/v1/impostor/';
  // private rootUrl = 'http://localhost:8080/api/v1/impostor/';
  private gamesUrl = this.rootUrl + 'games/';
  private createUrl = this.gamesUrl + 'add';
  private deleteUrl = this.gamesUrl + 'delete';
  private tasksUrl = this.gamesUrl + 'tasks';


  private playerStatusUrl = this.gamesUrl + '';

  getGameUrl( gameId: string, postFix: string): string {
      return this.gamesUrl + gameId + postFix;
  }


  constructor( private http: HttpClient,
      private messageService: MessageService) { }


  getGames(): Observable<Game[]> {
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type':  'application/json',
      })
    };


    return this.http.get<Game[]>(this.gamesUrl, httpOptions)
      .pipe(
        catchError(this.handleError<Game[]>('getGames', []))
      );
  }

  createGame( name: string ): Observable<Game> {
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Origin': 'albrechtweb.com',
      'Access-Control-Allow-Origin': '*'
      })
    };

    return  this.http.post<Game>(this.createUrl, name,  httpOptions)
      .pipe(
        catchError(this.handleError<Game>('createGame', this.dummyGame))
      );
  }

   deleteGame( gameId: number ) {
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*'
      })
    };

    this.http.post(this.deleteUrl, gameId,  httpOptions).subscribe( result => {
      this.messageService.add( `game deleted ${result}` );
    })
     
  }

  getGameById( gameId: string): Observable<Game> {
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*'
      })
    };

    const gameUrl = this.getGameUrl( gameId, '');

    return  this.http.get<Game>(gameUrl, httpOptions)
          .pipe(
          catchError(this.handleError<Game>('getGame', this.dummyGame))
        );

  }

  createPlayer( gameId: string, name: string ): Observable<Player> {
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*'
      })
    };

    const createPlayerUrl = this.getGameUrl( gameId, '/players/add');

    return  this.http.post<Player>(createPlayerUrl, name,  httpOptions)
      .pipe(
        catchError(this.handleError<Player>('createPlayer', this.dummyPlayer))
      );
  }

  killPlayer( gameId: string, playerId: string): Observable<Game> {
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*'
      })
    };
    const gameUrl = this.getGameUrl(gameId, '/players/kill');

    return this.http.post<Game>(gameUrl, playerId,  httpOptions)
         .pipe(
          catchError(this.handleError<Game>('killPlayer', this.dummyGame ) )
      );

  }

  restorePlayer( gameId: string, playerId: string): Observable<Game> {
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*'
      })
    };
    const gameUrl = this.getGameUrl(gameId, '/players/restore');

    return this.http.post<Game>(gameUrl, playerId,  httpOptions)
         .pipe(
          catchError(this.handleError<Game>('restorePlayer',this.dummyGame) )
      );

  }


  markTaskComplete( gameId: string, playerId: string, taskId: number): Observable<Game> {
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*'
      })
    };
    const gameUrl = this.getGameUrl(gameId, '/task/completed');

    return this.http.post<Game>(gameUrl, {'playerId': playerId, 'taskId': taskId},  httpOptions)
         .pipe(
          catchError(this.handleError<Game>('taskComplete', this.dummyGame ) )
      );

  }

  startGame( gameId: number) {
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*'
      })
    };
    const gameUrl = this.getGameUrl('' + gameId, '/start');

    return this.http.post<Game>(gameUrl, {gameId: gameId},  httpOptions)
         .pipe(
          catchError(this.handleError<Game>('startGame', this.dummyGame) )
      );

  }

  getTasks( ): Observable<Task[]> {
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*'
      })
    };

    const url = this.tasksUrl;

    return  this.http.get<Task[]>(url)
          .pipe(
          catchError(this.handleError<Task[]>('getTasks', []))
        );

  }



  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`HeroService: ${message}`);
  }
}
