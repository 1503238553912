import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatTableModule,
  MatSortModule,
  MatMenuModule,
  MatIconModule,
  MatToolbarModule,
  MatListModule,
  MatCardModule,
  MatSlideToggleModule,
  MatInputModule,
  MatRadioModule,
  MatDialogModule,
  MatExpansionModule,
  MatSelectModule,
  MatSnackBarModule,
} from '@angular/material';

import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';

import { AppComponent } from './app.component';
import { AppRoutingModule, routingComponents } from './app.routes';
import { AnswerComponent } from './answer/answer.component';
import { NewPlayerDialogComponent } from './start-page/new-player-dialog/new-player.dialog.component';
import { NewGameDialogComponent } from './start-page/new-game-dialog/new-game.dialog.component';
import { QuizzManagerComponent } from './quizz-manager/quizz-manager.component';
import { PlayQuizzComponent } from './play-quizz/play-quizz.component';


import { MessageComponent } from './message/message.component';
import { ImpostorService } from './services/impostor.service';
import { MessageService } from './services/message.service';
import { PrinterComponent } from './printer/printer.component';


@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    AnswerComponent,
    NewPlayerDialogComponent,
    NewGameDialogComponent,
    QuizzManagerComponent,
    PlayQuizzComponent,
    MessageComponent,
    PrinterComponent,
  ],
  entryComponents: [NewPlayerDialogComponent, NewGameDialogComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatExpansionModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatCardModule,
    MatSlideToggleModule,
    MatInputModule,
    MatRadioModule,
    FormsModule,
    MatDialogModule,
    MatSelectModule,
    MatSnackBarModule,
    HttpModule,
    HttpClientModule,
  ],
  providers: [ImpostorService, MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
