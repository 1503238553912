import { Component, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material';
import { Router } from '@angular/router';
import { NewGameDialogComponent } from '../start-page/new-game-dialog/new-game.dialog.component';
import { ImpostorService } from '../services/impostor.service';
import { Game } from '../domain/game';

@Component({
  selector: 'app-quizz-manager',
  templateUrl: './quizz-manager.component.html',
  styleUrls: ['./quizz-manager.component.css']
})
export class QuizzManagerComponent implements OnInit {
  public games: Game[];

  constructor(private impostorService: ImpostorService, private router: Router, public dialog: MatDialog) { }

  ngOnInit() {
    this.refreshGameList();
  }

  manageGame(gameId: number) {
    this.router.navigate([`/manage/${gameId}`]);
  }
  
  deleteGame(gameId: number) {
    this.impostorService.deleteGame(gameId);
    this.refreshGameList();
  }

  createGame() {
    const dialogRef = this.dialog.open(NewGameDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.impostorService.createGame(result).subscribe( test => {
           this.refreshGameList();
      } )

    });
  }

  refreshGameList() {
        this.impostorService.getGames().subscribe( games => {
      this.games = games;
    });

  }
}
