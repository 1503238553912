import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Game } from '../domain/game';
import { Player} from '../domain/player';

import { interval } from "rxjs";
import { startWith, switchMap } from "rxjs/operators";

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ImpostorService } from '../services/impostor.service';
import { TimeInterval } from 'rxjs/internal/operators/timeInterval';

@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.css']
})
export class QuestionListComponent implements OnInit {
  game: Game;

  id: string;
  sub: Subscription;
  showDebug = true;

  interval: any;

  constructor(private impostorService: ImpostorService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];

      this.updateData();
      this.interval = setInterval(() => { 
          this.updateData(); 
        }, 5000);
    });
  }

  updateData() {
      this.impostorService.getGameById(this.id).subscribe( game => {
        this.game = game;
      });
  }

  OnDestroy() {
    clearInterval(this.interval);
    this.sub.unsubscribe();
  }

  kill(q: Player ) {
    this.impostorService.killPlayer(this.id, q.playerId.toString()).subscribe( game => {
        this.game = game;
     });
  }

  restore(q: Player ) {
    this.impostorService.restorePlayer(this.id, q.playerId.toString()).subscribe( game => {
        this.game = game;
     });
  }

  isGameReady(): boolean {
    return this.game.state === "READY";
  }

  startGame() {
    this.impostorService.startGame(this.game.gameId).subscribe( game => {
        this.game = game;
     });
 
  }

   showPlayer(q: Player ) {
     if ( q.status === 1) {
       return true;
     } else {
       return false;
     }
  }


}
