import { Component, OnInit } from '@angular/core';
import { ImpostorService } from '../services/impostor.service';
import { Task } from '../domain/task';

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.css']
})
export class PrinterComponent implements OnInit {

  allTasks: Task[] = [];
  selectedTasks: Task[] = [];
  stations: string[] = [];

  rooms: string[] = [];

  selectedRoom = '';
  selectedStation = '';

  constructor( private impostorService: ImpostorService ) { }

  ngOnInit() {
    this.impostorService.getTasks().subscribe( tasks => {
      this.allTasks = tasks;
      // build list of rooms
      for( var i = 0; i < tasks.length; i++) {
        let task:Task = tasks[i];
        if( !this.rooms.includes(task.room) ){
          this.rooms.push(task.room);
        }
      } 

    })
  }

  onRoomChange(newRoom: string) {
    console.log(newRoom);
    this.getStationsInRoom(newRoom);
    this.selectedRoom = newRoom;
  }

  onStationChange(newStation: string) {
    console.log(newStation);
    this.selectedStation = newStation;
    this.getSelectedTasks();

  }

  getSelectedTasks() {
    this.selectedTasks = [];
    // build list of stations in given room
    for ( var i = 0; i < this.allTasks.length; i++) {
      let task: Task = this.allTasks[i];
      if ( this.selectedRoom === task.room) {
        if ( this.selectedStation ===  task.station) {
          if ( !this.selectedTasks.includes(task)) {
              this.selectedTasks.push(task);
          }
        }
      }
    } 
    console.log(this.selectedTasks);
  }

  getStationsInRoom( room: string) {
    this.stations = [];
    // build list of stations in given room
    for( var i = 0; i < this.allTasks.length; i++) {
      let task: Task = this.allTasks[i];
      if( room === task.room) {
        if ( !this.stations.includes(task.station)) {
            this.stations.push(task.station);
        }
      }
    } 
  }
}
