import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImpostorService } from '../services/impostor.service';
import { Game } from '../domain/game';
import { Player } from '../domain/player';
import { Task} from '../domain/task';
import { Subscription } from 'rxjs/Subscription';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-play-quizz',
  templateUrl: './play-quizz.component.html',
  styleUrls: ['./play-quizz.component.css']
})
export class PlayQuizzComponent implements OnInit {
  gameId: string;
  playerId: string;

  game: Game;
  player: Player;

  showDebug = false;
  showResults = true;

  sub: Subscription;
  interval: any;

  constructor(
    private impostorService: ImpostorService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar) { 

    }

  ngOnInit() {

    this.player = {playerName: '',playerId:0, playerType: 'crew',status:1,taskList:[], remainingTasksCount:0};
    this.game = {gameId:0, gameName:'', state:'',players:[], requiredPlayerCount: 0, keyPhrase: ''};

    this.sub = this.route.params.subscribe(params => {
      this.gameId = params['gameId'];
      this.playerId = params['playerId'];
      this.impostorService.getGameById(this.gameId).subscribe( game => {
        this.game = game;
        this.player = this.getPlayerByPlayerId( this.game, this.playerId);
      });

    });

    this.updateData();
    this.interval = setInterval(() => { 
          this.updateData(); 
        }, 5000);
  }

  updateData() {
      this.impostorService.getGameById(this.gameId).subscribe( game => {
        this.updatePlayers(game);
        this.reportPlayerActivity(game);
        this.game = game;
        const player = this.getPlayerByPlayerId( game, this.playerId);
        this.player.status = player.status;
        this.player.playerType = player.playerType;
      });
  }

  updatePlayers( game: Game) {
     for (var i = 0; i < game.players.length; i++) {
        let p: Player = game.players[i];
        this.findOrAddPlayer(p);
     }
  }

  findOrAddPlayer( player: Player) : Player {
     for (var i = 0; i < this.game.players.length; i++) {
      let p: Player = this.game.players[i];
      if ( player.playerId === p.playerId) {
        return p;
      }
    }
    this.game.players.push(player);
    // report the change to the user
    const message = player.playerName + ' has joined the game.';
    // console.log(message );
    this.openSnackBar(message);
    return player;
  }

  reportPlayerActivity( game: Game ) {
    // for each player
     for (let i = 0; i < game.players.length; i++) {
      let p: Player = game.players[i];

      if ( this.player.playerId !== p.playerId) {
        let orig: Player = this.findOrAddPlayer(p);

        if ( p.remainingTasksCount < orig.remainingTasksCount) {
          // update the count
          orig.remainingTasksCount = p.remainingTasksCount;

          // report the change to the user
          const message = p.playerName + ' has completed a task.';
          // console.log(message );
          this.openSnackBar(message);
        }
      }
    }
  }


  getPlayerByPlayerId( game: Game, playerId: string): Player {
     for (var i = 0; i < game.players.length; i++) {
      let p = game.players[i];
      if( playerId === p.playerId.toString() ) {
        return p;
      }
    }
  }
  
  OnDestroy() {
    this.sub.unsubscribe();
  }

  isComplete( task: Task): boolean {
    return task.status === 'COMPLETE';

  }

   isTaskDisabled( task: Task): boolean {
      if(task.status === 'COMPLETE' ) {
        return true;
      }

      if( this.game.state === 'STARTED') {
        return false;
      }

      return true;

  }
  onSearchChange(task: Task, searchValue: string): void {
    task.input = searchValue;  
    console.log(searchValue);
  }

  isAlive(): boolean {
    if( this.player) {
      return this.player.status > 0;
    } else {
      return false;
    }
  }

  playerHealth(): string {
    if (this.isAlive()) {
      return 'Alive';
    }
    return 'Dead';
  }

  onInputChange( task: Task, changeValue: string) {
    task.input = changeValue;
    if( task.code === changeValue) {
      this.impostorService.markTaskComplete( this.gameId, this.playerId, task.taskId).subscribe( game => {
        task.status = 'COMPLETE';
      })  
    }
    console.log(changeValue);
  }

  onStatusChange( changeValue: boolean) {
    if(this.player) {
      if( changeValue === true) {
        this.impostorService.restorePlayer(this.gameId, this.playerId).subscribe( game => {
        this.game = game;
        this.player = this.getPlayerByPlayerId( this.game, this.playerId);
        });

      } else {
        this.impostorService.killPlayer(this.gameId, this.playerId).subscribe( game => {
        this.game = game;
        this.player = this.getPlayerByPlayerId( this.game, this.playerId);
        });
      }
    }
    
    // this.openSnackBar(changeValue.toString());
    console.log(changeValue);
  }

  openSnackBar(message: string) {
      this.snackBar.open(message, 'Dismiss', {
      duration: 3000
    });
  }
}
