import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {GameSettings} from './../../domain/gamesettings';

@Component({
    selector: 'app-newgame-dialog',
    templateUrl: './new-game.dialog.component.html',
})
export class NewGameDialogComponent {

    settings: GameSettings = { gameName: '', numPlayers: 8, keyPhrase: ''};

    constructor(
        public dialogRef: MatDialogRef<NewGameDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    ok() {
        if (!!this.settings.gameName) {
            this.dialogRef.close(this.settings);
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    isDisabled(): boolean {
        if( this.settings.gameName.length > 0) {
            return false;
        }
        return true;
    }

}
