import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Game } from '../../domain/game';

@Component({
    selector: 'app-newplayer-dialog',
    templateUrl: './new-player.dialog.component.html',
})
export class NewPlayerDialogComponent {
    name = '';
    keyPhrase = '';
    game: Game;

    constructor(
        public dialogRef: MatDialogRef<NewPlayerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Game) {
            this.game = data;
         }

    onNoClick(): void {
        this.dialogRef.close();
    }

    ok() {
        if (!!this.name) {
            this.dialogRef.close(this.name);
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    
    isDisabled(): boolean {
        if ( this.name === '') {
            return true;
        }

        if ( this.game.keyPhrase === this.keyPhrase) {
            return false;
        }
        return true;
    }

}
