import {Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {Router} from '@angular/router';

import {NewPlayerDialogComponent} from './new-player-dialog/new-player.dialog.component';
import {NewGameDialogComponent} from './new-game-dialog/new-game.dialog.component';
import {Game} from '../domain/game';
import {Player} from '../domain/player';
import { ImpostorService} from '../services/impostor.service';


@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.css'],
})
export class StartPageComponent implements OnInit {
  public games: Game[];

  constructor(private impostorService: ImpostorService, private router: Router, public dialog: MatDialog) {}

  ngOnInit() {
    
    this.impostorService.getGames()
      .subscribe(games => this.games = games);
  }

  manageGame(gameId: string) {
    this.router.navigate([`/manage/${gameId}`]);
  }

  playGame(game: Game) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = game;
    dialogConfig.width = '250px';

    const dialogRef = this.dialog.open(NewPlayerDialogComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(result => {
      if (!result) { return; }
    
      this.impostorService.createPlayer(game.gameId.toString(), result).subscribe( player => {

        let playerId = player.playerId;

        // set current player Id in service
        this.router.navigate([`/play/${game.gameId}/${playerId}`]);
      });
    });
  }


  newGame() {
    const dialogRef = this.dialog.open(NewPlayerDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      
      this.impostorService.createGame(result).subscribe( game => {
          this.impostorService.getGames()
          .subscribe(games => this.games = games);       
      } )

    });
  }

  createGame() {
    const dialogRef = this.dialog.open(NewGameDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      
      this.impostorService.createGame(result).subscribe( game => {
          this.impostorService.getGames()
          .subscribe(games => this.games = games);       
      } )

    });
  }

  getPlayerCount(game: Game): string {
    return '' + game.players.length + '/' + game.requiredPlayerCount; 
  } 
}
